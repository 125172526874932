<template>
  <v-dialog v-model="aberto" width="90%" persistent :fullscreen="$vuetify.breakpoint.xsOnly"
            @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal mt-n1 mb-5">
        <div class="d-flex justify-space-between w-100">
          <span>{{ titulo || 'Arquivo Anexado'}}</span>
          <v-btn icon dark @click="">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-img
            v-if="['jpg', 'png', 'jpeg', 'webp'].includes(getTypeFile(url_arquivo))"
            :src="(url_arquivo)"
            max-width="100%"
            max-height="800px"
            contain
        ></v-img>
        <iframe v-else-if="getTypeFile(url_arquivo) == 'pdf'"
                :src="encodeURI(url_arquivo)"
                height="1200"
                width="100%"
        ></iframe>
        <span v-else> Formato Invalido para Visualização !</span>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="orange darken-3" text @click="fechar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DialogShowArquivo",
  components: {},
  props: {
    aberto: Boolean,
    titulo: String,
    url_arquivo: String
  },
  data() {
    return {}
  },
  methods: {
    fechar() {
      this.$emit('fechar', false);
    },
    getTypeFile(url) {
      if (url == null) return;
      var split = url.split(".");
      var a = split.at(-1);
      return a
    },
  },
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
